import React, { Component } from 'react';
import Layout from '../components/layout';

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

class Contacto extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const form = e.target;

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => {
        document.getElementById('contact-form').reset();
        alert('Gracias!\n Vamos a responder el mas pronto posible.');
      })
      .catch(error => alert(error));
  }

  render() {
    return (
      <Layout>
        <section className="max-w-6xl p-4 lg:p-8 mx-auto flex flex-col md:flex-row ">
          <div className="w-full py-8 pr-6 md:w-1/2">
            <h1 className="text-3xl mb-4 leading-tight font-extrabold text-teal-700">
              Contacto
            </h1>
            <p className="mb-6 text-base">
              Gracias por considirarnos. Basado en las cantidades y los tipos de
              nuestros pedidos, nuestros clients hablan directamente con nuestro
              equipo para asegurar envios son precisos y tienen precios
              apropiados.
            </p>

            <p className="mb-6">
              Llamamos{' '}
              <a href="tel:+543765051777" className="underline text-blue-600">
                +54 376 5051777
              </a>{' '}
              o mandanos un mensaje con la forma y responderemos el mas pronto
              posible. Gracias.
            </p>

            <span className="text-sm leading-5 font-bold tracking-wider text-teal-700 uppercase">
              Ubicación
            </span>
            <address className="block pb-5">
              Ruta Nacional 12 Km. 131369 <br />
              Candelaria, Misiones
            </address>

            <span className="text-sm leading-5 font-bold tracking-wider text-teal-700 uppercase">
              Telefono (Argentina)
            </span>
            <a href="tel:+543765051777" className="block pb-5">
              +54 376 5051777
            </a>

            <span className="text-sm leading-5 font-bold tracking-wider text-teal-700 uppercase">
              Email
            </span>
            <a
              href="mailto:indubasrl@yahoo.com.ar"
              className="block pb-5 underline text-blue-600"
            >
              indubasrl@yahoo.com.ar
            </a>
          </div>
          <div className="w-full py-8 sm:pr-6 md:w-1/2">
            <form
              id="contact-form"
              name="contacto-del-sitio"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={this.handleSubmit}
              className="bg-white p-6 md:p-10 shadow-lg"
            >
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="form-name" value="contact" />
              <input name="bot-field" onChange={this.handleChange} />

              <label className="block py-2">
                <span className="sans text-gray-700 block py-1 px-2 uppercase text-sm font-bold">
                  Tu nombre
                </span>
                <input
                  type="text"
                  name="name"
                  onChange={this.handleChange}
                  className="border-2 border-gray-800 border-solid border rounded w-full p-2"
                />
              </label>

              <label className="block py-2">
                <span className="sans text-gray-700 block py-1 px-2 uppercase text-sm font-bold">
                  Tu email
                </span>
                <input
                  type="email"
                  name="email"
                  onChange={this.handleChange}
                  className="border-2 border-gray-800 border-solid border rounded w-full p-2"
                />
              </label>

              <label className="block py-2">
                <span className="sans text-gray-700 block py-1 px-2 uppercase text-sm font-bold">
                  Tu mensaje
                </span>
                <textarea
                  name="message"
                  onChange={this.handleChange}
                  className="border-2 border-gray-800 border-solid border rounded w-full p-2"
                />
              </label>
              <button className="btn btn-primary" type="submit">
                Mandar
              </button>
            </form>
          </div>
        </section>
      </Layout>
    );
  }
}

export default Contacto;
